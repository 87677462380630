<template>
  <div
    :style="`--background-color: ${backgroundColor};--body-color: ${bodyColor};`"
    id="app"
    :class="[
      'route--' + ($path('name', $route) || '').toLowerCase(),
      isOnTop ? 'page--is-top' : 'page--is-scrolled',
    ]"
  >
    <div class="demo-indicator" v-if="isDemo">
      <FontAwesomeIcon icon="exclamation-circle" />
      <span>{{ $t('thisIsADemo') }}</span>
    </div>
    <div
      v-if="$route.meta.navigation !== false"
      class="nav"
      :class="[
        'nav--' + view.toLowerCase(),
        { ['nav--' + view.toLowerCase() + '-expanded']: isExpanded },
      ]"
    >
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="1rem 0"
          :action="mainAction || redirect(['Project', 'Residential'], { scrollTo: 0 })"
          :isOpen.sync="isExpanded"
        />
      </div>
    </div>
    <router-view />
    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NavigationComponent } from 'vue-elder-navigation'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import { Limit } from '@/filters'
import { DarkTheme } from '@/utils'
import loadCM from '@/gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    isSingular() {
      return API.projectType === 'singular'
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      if (this.$route.name === 'Project' && !this.isSingular)
        return this.isOnTop ? this.logos.light : this.logos.dark
      return this.logos.dark
    },
    isDemo() {
      return this.item.demo || false
    },
    projectName() {
      return this.$path('item.name') || ''
    },
    seo() {
      return this.$path('item.customFields.seo') || {}
    },
    hasStatsTotal() {
      return this.$path('item.stats.total')
    },
    labels() {
      return this.$path('item.metadata.labels') || {}
    },
    hasFlatfinder() {
      return !!this.$path('item.flatfinders.length')
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    hasAttachments() {
      return this.$path('item.media.attachments.length')
    },
    brochure() {
      return this.$path('item.media.brochure') || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    backgroundColor() {
      return this.$path('customFields.background-color', this.item) || '#FFFFFF'
    },
    bodyColor() {
      if (DarkTheme(this.backgroundColor)) return '#FFFFFF'
    },
    posts() {
      return this.$path('item.posts') || []
    },
    subpages() {
      if (!this.item) return
      return this.$path('item.customFields.subpages') || []
    },
    gallery() {
      return this.$path('item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },
    defaultItems() {
      return [
        {
          id: 'home',
          label: this.$t('theProject'),
          action: this.mainAction,
          condition: this.mainAction,
        },
        {
          id: 'flatfinder',
          label:
            this.$path('item.customFields.title-overrides.flatfinder') ||
            KvassConfig.get('customLabels.flatfinder') ||
            this.$tc('flatfinder'),
          exact: true,
          action: this.redirect(['Project', 'Residential'], { hash: '#flatfinder' }),
          condition: this.hasFlatfinder && this.hasStatsTotal,
        },
        {
          id: 'residentials',
          label: KvassConfig.get('customLabels.residentialList') || this.$tc('residential', 2),
          exact: true,
          action: this.redirect(['Project', 'Residential'], { hash: '#residentials' }),
          condition: !this.hasFlatfinder && this.hasResidentials,
        },
        ...this.posts.map(i => {
          if (!this.$path('customFields.page-settings.display-in-menu', i)) return {}
          return {
            id: i.title,
            label: i.title,
            exact: true,
            action: this.redirect(['Post'], { params: { slug: i.slug }, forceRedirect: true }),
          }
        }),
        {
          id: 'gallery',
          label: this.$tc('image', 2),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#gallery',
          }),
          condition: this.gallery.length,
        },
        {
          id: 'map',
          label: this.$t('map'),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#map',
          }),
        },
        {
          id: 'brochure',
          label: this.$t('brochure'),
          exact: true,
          action: () => {
            if (this.brochure.length > 1) return
            AssetBus.emit('download', {
              type: 'brochure',
              strategy: this.brochureStrategy,
              asset: this.brochure[0],
            })
          },
          items:
            this.brochure.length > 1
              ? this.brochure.map(b => ({
                  label: Limit(b.name, 30),
                  action: () => {
                    AssetBus.emit('download', {
                      type: 'brochure',
                      strategy: this.brochureStrategy,
                      asset: b,
                    })
                  },
                  icon: ['fad', 'file-pdf'],
                }))
              : undefined,
          condition: this.brochure.length,
        },
        {
          id: 'attachments',
          label: this.$tc('attachment', 2),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#attachments',
          }),
          condition: this.hasAttachments,
        },
        {
          id: 'lead',
          label: this.$t('leadFormTitle'),
          class: 'elder__navigation-component--primary',
          action: this.redirect(['Project', 'Residential'], {
            hash: '#lead',
          }),
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
        .map((i, index) => ({ ...i, order: (index + 1) * 10 }))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || forceRedirect)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    const debounce = fn => {
      let frame
      return (...params) => {
        if (frame) cancelAnimationFrame(frame)
        frame = requestAnimationFrame(() => fn(...params))
      }
    }

    const storeScroll = () => {
      this.isOnTop = window.scrollY <= 5
    }

    document.addEventListener('scroll', debounce(storeScroll), { passive: true })
    storeScroll()

    this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
#app {
  background-color: var(--background-color);
  color: var(--body-color);
}
.nav {
  top: 0;
  width: 100%;
  z-index: get-layer('nav');
  background: white;
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);

  transition: all 150ms ease-out;

  .route--project.page--is-scrolled & {
    background: white;
    color: $dark;
    box-shadow: 0 10px 15px -10px rgba(black, 0.05);
    border-bottom: 1px solid $border-color;

    .elder__navigation-component,
    .elder__navigation-bars {
      color: var(--vue-elder-text-color, #444);
    }
  }

  .elder-dropdown__content-box .elder__navigation-component {
    color: var(--vue-elder-text-color, #444) !important;
  }
  .elder__navigation--responsive .elder__navigation-bars {
    color: $dark;
  }

  :not(.route--project) & {
    position: sticky;
  }

  &--project-expanded {
    backdrop-filter: blur(1rem);
  }

  .route--project & {
    background: linear-gradient(to bottom, black -200%, transparent 100%);
    box-shadow: none;
    position: fixed;

    .elder__navigation {
      &-bars {
        color: white;
      }
      &-component {
        color: white;
      }
    }
  }
}
</style>
