<template>
  <Lead
    class="residential-lead"
    :reference="{ onModel: 'Project', ref: $path('project.id', item) }"
    :scope="{ onModel: 'Residential', ref: item.id }"
  />
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Residential', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.residential-lead {
  color: var(--body-color);
  max-width: 800px;
  margin: 0 auto;

  .lead__form {
    background-color: transparent;
    .elder-input__label {
      color: var(--body-color);
    }
  }
}
</style>
