<template>
  <Gallery :value="items" scroll-anchor="floorplan" class="residential-floorplan" size="contain" />
</template>

<script>
import { mapState } from 'vuex'
import Gallery from '@kvass/template-gallery/Types/Gallery'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return (this.$path('item.media.floorplan') || []).map(e => ({
        caption: e.name,
        url: e.url,
        type: e.type,
      }))
    },
  },
  components: {
    Gallery,
  },
}
</script>

<style lang="scss">
.residential-floorplan {
  color: $dark;
  .gallery__caption {
    bottom: 1rem;
    left: 1rem;
  }
}
</style>
