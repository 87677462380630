<template>
  <Roles class="project-roles" :item="item" v-bind="$attrs" />
</template>

<script>
import { mapState } from 'vuex'
import Roles from '@/components/Roles'

export default {
  computed: {
    ...mapState('Root', ['item']),
  },
  components: {
    Roles,
  },
}
</script>
