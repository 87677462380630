<template>
  <Lead class="project-lead"  :reference="{ onModel: 'Project', ref: item.id }" />
</template>

<script>
import { mapState } from 'vuex'
import Lead from '@kvass/lead'

export default {
  computed: {
    ...mapState('Root', ['item']),
  },
  components: {
    Lead,
  },
}
</script>

<style lang="scss">
.project-lead {
  max-width: 800px;
  margin: 0 auto;
  color: var(--body-color);

  .lead__form {
    background-color: transparent;
    .elder-input__label {
      color: var(--body-color);
    }
  }
}
</style>
