import { path as RPath } from 'ramda'

const Clone = function() {
  return JSON.parse(JSON.stringify.apply(this, arguments))
}

function ScrollIntoView(selector, offset = 0) {
  let element = document.querySelector(selector)
  if (!element) return

  let elementPosition = element.getBoundingClientRect().top
  let offsetPosition = window.pageYOffset + elementPosition + offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
function GetRouteBasedValue(config = {}) {
  return function() {
    let selector = MakeArray(config[this.$route.name])
    let value = selector.map(s => this.$path(s, this.$store.state)).find(Boolean)

    return value || config.default
  }
}
function getDimensions(url) {
  return new Promise((resolve, reject) => {
    if (!url) return reject(new Error('Url is required'))
    let img = new Image()
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = reject
    img.src = url
  })
}

function RandomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function MakeArray(val) {
  if ([undefined, null].includes(val)) return []
  return val instanceof Array ? val : [val]
}

function Loop(items, cb, options = {}) {
  let { immediate = true, timing = 4000 } = options
  let current, interval, core

  let start = () => {
    stop()
    interval = setInterval(next, timing)
    next()
    return core
  }
  let stop = () => {
    if (!interval) return
    clearInterval(interval)
    return core
  }
  let reset = () => {
    goTo(0)
    return core
  }
  let next = () => {
    current = items[getIndex(1)]
    cb(current)
    return core
  }
  let prev = () => {
    current = items[getIndex(-1)]
    cb(current)
    return core
  }
  let goTo = index => {
    current = items[index]
    cb(current)
    return core
  }
  let getIndex = delta => {
    delta = delta % items.length
    let currentIndex = items.indexOf(current)
    let targetIndex = currentIndex + delta

    if (targetIndex > items.length - 1) return targetIndex - items.length
    if (targetIndex < 0) return items.length - targetIndex
    return targetIndex
  }

  core = {
    start,
    stop,
    reset,
    next,
    prev,
    goTo,
    getIndex,
    destroy: stop,
  }

  if (items.length === 1) {
    cb(items[0])
    return core
  }

  if (immediate) start()

  return core
}

function GetCustomField(key, obj = {}, path = '') {
  return RPath([key, ...(path ? path.split('.') : [])], obj)
}

function DownloadUrl(url, name) {
  let link = document.createElement('a')
  link.setAttribute('target', '_blank')
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}
function SplitHtml(value, pattern = '<h2>') {
  if (!value) return []
  let splitter = '<<SPLITTER>>'
  let regex = new RegExp(pattern, 'g')
  return value
    .replace(regex, splitter + pattern)
    .split(splitter)
    .filter(Boolean)
}

function ScrollToAnchor(value) {
  let target = document.querySelector('.scroll-anchor--type-anchor.scroll-anchor--value-' + value)
  if (!target) return
  target.scrollIntoView({ behavior: 'smooth' })
}

function DarkTheme(color) {
  if (!color) return
  const hex = color.replace('#', '')
  const c_r = parseInt(hex.substring(0, 0 + 2), 16)
  const c_g = parseInt(hex.substring(2, 2 + 2), 16)
  const c_b = parseInt(hex.substring(4, 4 + 2), 16)
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness < 155
}

function Capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substring(1)
}
function TransformTag(content, transform = ['h2', 'h1']) {
  if (!content) return content
  return content.replace(transform[0], transform[1])
}
export {
  DownloadUrl,
  Clone,
  getCookie,
  getDimensions,
  RandomBetween,
  MakeArray,
  Loop,
  ScrollIntoView,
  GetCustomField,
  GetRouteBasedValue,
  SplitHtml,
  ScrollToAnchor,
  DarkTheme,
  Capitalize,
  TransformTag,
}
