<template>
  <div class="map">
    <LazyLoad>
      <MapComponent :center="center" v-bind="$attrs" />
    </LazyLoad>
    <div class="map__address" v-if="value && value.formatted">
      {{ value.formatted }}
    </div>
    <div id="map" class="scroll-anchor"></div>
  </div>
</template>

<script>
import MapComponent from './Map'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    center() {
      return this.$path('location.coordinates', this.value)
    },
  },
  components: {
    MapComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.map {
  position: relative;
  text-align: center;
  background: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.9);
  border: none;
  color: white;

  &__address {
    font-size: 1.25em;
    padding: 1rem;

    @include respond-below('phone') {
      font-size: 1em;
    }
  }
}
</style>
