<template>
  <div class="project-stages-preview">
    <div v-for="(item, index) in items" :key="index" class="project-stages-preview__item">
      <div
        class="project-stages-preview__image"
        :style="{ backgroundImage: `url(${item['image-stage-preview'].url})` }"
        @click="openModal(index)"
      />
      <div class="project-stages-preview__button-wrapper">
        <div class="project-stages-preview__content" v-html="item['text-stage-preview']"></div>

        <div class="row wrap project-stages-preview__actions">
          <ButtonComponent
            v-if="getStatus(index)"
            label="Les mer"
            :icon="['far', 'arrow-right']"
            tag="button"
            @click="openModal(index)"
          />
          <!-- <BrochureComponent :value="brochure" theme="default" /> -->
        </div>
      </div>
    </div>
    <Modal
      v-if="current"
      class="project-stages-preview__modal"
      clickaway
      :show="showModal"
      @close="showModal = false"
    >
      <div class="project-stages-preview__modal-content">
        <div class="project-stages-preview__modal-content-wrapper">
          <Slider
            class="project-stages-preview__modal-content-images"
            :value="current['stage-images']"
            :manual-navigation="true"
          >
          </Slider>
          <div
            class="project-stages-preview__modal-content-description"
            v-html="current['stage-text']"
          ></div>

          <ButtonComponent
            v-if="current['stage-flatfinder']"
            class="project-stages-preview__modal-content-flatfinder-button"
            label="Boligvelger"
            :icon="['far', 'arrow-right']"
            tag="button"
            @click="goToFlatfinder(current)"
            theme="primary"
          />

          <ButtonComponent
            class="project-stages-preview__modal-content-close-button"
            label="Lukk"
            :icon="['far', 'times']"
            @click="showModal = false"
            tag="button"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import BrochureComponent from '@/components/Brochure'
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'
import Slider from '@kvass/media-render/Slider'
import { ModalComponent as Modal } from 'vue-elder-modal'
import { eventBus } from '@kvass/flatfinder/src/Component'

export default {
  data() {
    return {
      showModal: false,
      current: null,
    }
  },
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return GetCustomField('stages', this.item.customFields) || []
    },

    brochure() {
      return this.$path('item.media.brochure') || []
    },
  },
  methods: {
    close() {
      this.showModal = false
    },
    openModal(index) {
      if (!this.getStatus(index)) return
      this.current = this.items[index]

      this.showModal = true
    },
    getStatus(index) {
      if (this.items[index]['stage-images']) return true
      return false
    },
    goToFlatfinder(value) {
      let id = value['stage-flatfinder']
      if (!id) return
      let result = this.item.flatfinders.find(e => e.id === id)
      if (!result) return
      this.close()
      eventBus.emit('setCurrent', result)
      return document.querySelector(`#flatfinder`).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
  components: {
    Slider,
    Modal,
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.project-stages-preview {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);

  @include respond-below('phone') {
    grid-template-columns: 1fr;
    grid-gap: 4rem;

    .elder-modal {
      padding: 0rem;
    }
  }

  &__image {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-height: 500px;

    &::after {
      content: '';
      padding-bottom: 100%;
      display: block;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    font-size: 0.7em;
    @include gap(0.25rem);

    margin-top: auto;
  }

  &__content {
    margin-top: 2rem;
    text-align: center;

    @include respond-below('phone') {
      margin-top: 2rem;
      text-align: center;
    }

    h2 {
      margin-bottom: 0.5rem;

      & + p {
        margin-top: 0;
      }
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    flex-grow: 1;

    @include respond-below('phone') {
      padding: 0 2rem;
    }
  }

  &__modal {
    z-index: get-layer('stages');

    &-content {
      background-color: white;
      max-height: 100%;
      max-width: 1400px;
      width: 100%;
      overflow: auto;
      margin: auto;
      color: $dark;

      &-wrapper {
        display: flex;
        flex-direction: column;
      }
      &-description {
        padding: 3rem 3rem 1rem;
      }

      &-flatfinder-button {
        margin: 1rem 3rem;
        align-self: flex-start;
        justify-self: center;
        @include respond-below('phone') {
          display: flex;
          align-self: center;
          margin: 1rem 3rem 0;
        }
      }

      &-close-button {
        display: flex;

        margin: 2rem 0;
        align-self: center;
        justify-self: center;
        @include respond-below('phone') {
          display: flex;
        }
      }

      &-images {
        margin-bottom: auto;
        max-height: 600px;

        &::after {
          content: '';
          padding-bottom: 100%;
          display: block;
        }
      }
    }
  }
}
</style>
