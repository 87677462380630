<template>
  <Masonry :value="items" class="project-masonry" />
</template>

<script>
import { mapState } from 'vuex'
import Masonry from '@kvass/template-gallery/Types/Masonry'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.gallery') || []
    },
  },
  components: {
    Masonry,
  },
}
</script>

<style lang="scss">
.project-masonry {
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
}
</style>
